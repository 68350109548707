<template>
  <div>
    <v-overlay v-if="selectedItemTweetId >= 0 && overlay" :value="overlay">
      <v-card style="padding-top: 20px" v-click-outside="onClickOutside">
        <v-icon
          style="padding-left: 50px; padding-bottom: 10px"
          @click="
            overlay = !overlay;
            loadingMedia = true;
          "
          >mdi-close</v-icon
        >
        <br />
        <div>
          <div
            style="
              text-align: center;
              max-height: 80vh;
              width: 80vw;
              max-width: 480px;
              overflow-y: auto;
            "
          >
            <!-- <img
              :src="
                dataListUpdatesCurrentPage[selectedItemIndex].thumbnail
              "
              @load="
                (e) => {
                  loadingMedia = false;
                  e.target.style.height = 'auto';
                  e.target.style.width = 'auto';
                  e.target.style.maxWidth = '90vw';
                  e.target.style.maxHeight = '80vh';
                }
              "
            /> -->
            <Tweet :id="selectedItemTweetId" :options="{ theme: 'dark' }"
              ><v-progress-circular indeterminate dark></v-progress-circular
            ></Tweet>
            <!-- <v-card-subtitle>
              {{ dataListUpdatesCurrentPage[selectedItemIndex].tweet }}<br />{{
                parseDate(dataListUpdatesCurrentPage[selectedItemIndex].date)
              }}
            </v-card-subtitle> -->
          </div>
        </div>
      </v-card>
    </v-overlay>
    <p></p>
    <h2
      style="
        background: black;
        color: white;
        margin-left: 10%;
        margin-right: 10%;
      "
    >
      🔴 Streaming:
    </h2>
    <p></p>
    <div v-if="upcomingStream != ''" style="text-align: center">
      <iframe
        :src="'https://www.youtube.com/embed/' + upcomingStream + '?autoplay=1'"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        style="width: 90vw; height: 75vh; max-width: 1500px"
      ></iframe>
    </div>
    <v-card
      dark
      v-else
      style="
        background: #111111;
        color: white;
        margin-left: 30%;
        margin-right: 30%;
        padding: 10px;
        text-align: center;
      "
    >
      Streaming will begin on the mission day.
    </v-card>
    <p></p>
    <v-tabs dark color="white" background-color="black" centered v-model="tab">
      <v-tab href="#launch-record">Launch Record</v-tab>

      <v-tab href="#upcoming-launches">Upcoming Events</v-tab>
    </v-tabs>
    <v-tabs-items style="background-color: black" v-model="tab">
      <v-tab-item value="launch-record">
        <p></p>
        <h2
          style="
            background: black;
            color: white;
            margin-left: 10%;
            margin-right: 10%;
          "
        >
          Launch Statistics in {{ this.this_year }}:
        </h2>

        <v-card
          dark
          style="
            background: black;
            text-align: center;
            margin-left: 10%;
            margin-right: 10%;
            margin-top: 50px;
            margin-bottom: 50px;
          "
        >
          <v-row>
            <v-col>
              <v-card width="100px" style="margin: 0 auto 0 auto">
                <h1 v-if="launch_this_year > 0">
                  {{ this.launch_this_year }}
                </h1>
                <h1 v-else>--</h1>
              </v-card>
              <p></p>

              <h3>Total launches in {{ this.this_year }}</h3>
            </v-col>

            <v-col>
              <v-card width="100px" style="margin: 0 auto 0 auto">
                <h1 v-if="launch_this_year > 0">
                  {{ this.launch_this_year - this.fail_this_year }}
                </h1>
                <h1 v-else>--</h1>
              </v-card>
              <p></p>

              <h3>Successful launches in {{ this.this_year }}</h3>
            </v-col>

            <v-col>
              <v-card width="100px" style="margin: 0 auto 0 auto">
                <h1 v-if="launch_this_year > 0">
                  {{ this.fail_this_year }}
                </h1>
                <h1 v-else>--</h1>
              </v-card>
              <p></p>

              <h3>Failed Launches in {{ this.this_year }}</h3>
            </v-col>
          </v-row>
        </v-card>
        <p></p>
        <div style="margin-left: 10%; margin-right: 10%; color: white">
          <h2>Recent Launches:</h2>
          <p></p>
        </div>
        <div style="margin-left: 15%; margin-right: 15%; color: white">
          <v-carousel v-if="carousel.length > 0" cycle>
            <v-carousel-item
              v-for="(item, i) in carousel"
              :key="i"
              :src="item.src"
              @click="
                selectedItemTweetId = item.tweetID;
                overlay = true;
              "
              reverse-transition="fade-transition"
              ><v-row>
                <div
                  class="text-h8"
                  style="
                    color: white;
                    margin: 30px;
                    background-color: rgba(10, 10, 10, 0.5);
                    padding: 10px;
                  "
                >
                  <p>{{ item.date }}</p>
                  <p>{{ item.description }}</p>
                </div>
              </v-row></v-carousel-item
            >
          </v-carousel>
        </div>
        <div style="margin-left: 10%; margin-right: 10%; color: white">
          <p></p>
          <h2>Launch Record Database:</h2>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dark
            ></v-text-field>
          </v-card-title>
          <v-data-table
            dark
            :headers="headers"
            :loading="loading"
            :items="dataListValues"
            :search="search"
            item-key="remote_id"
            :sort-by="['date', 'time']"
            :sort-desc="[true, true]"
            class="elevation-1"
            style="background: black"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, -1],
            }"
            :item-class="setRowStyle"
          >
            <template v-slot:[`item.tweetID`]="{ item }">
              <a
                v-if="item.tweetID != -1"
                target="_blank"
                @click="
                  selectedItemTweetId = item.tweetID;
                  overlay = true;
                "
                style="text-decoration: none"
              >
                ▶️
              </a>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
      <v-tab-item value="upcoming-launches">
        <p></p>
        <div style="margin-left: 10%; margin-right: 10%; color: white">
          <h2>Upcoming Launches:</h2>
          <v-data-table
            dark
            :headers="headersUpcoming"
            :loading="loadingUpcoming"
            :items="dataListValuesUpcoming"
            item-key="remote_id"
            :sort-by="['date']"
            :sort-desc="[false]"
            class="elevation-1"
            style="background: black"
            :item-class="setRowStyle"
          >
            <template v-slot:[`item.tweetID`]="{ item }">
              <a
                v-if="item.tweetID != -1"
                target="_blank"
                :href="twitterURL + item.tweetID"
                style="text-decoration: none"
              >
                🎥
              </a>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { Tweet } from "vue-tweet-embed";

export default {
  components: {
    Tweet,
  },
  props: ["tab_param"],
  data() {
    return {
      overlay: false,
      loadingMedia: true,
      selectedItemTweetId: -1,
      upcomingStream: "",
      tab: "launch-record",
      attrs: [
        "date",
        "description",
        "launchsite",
        "payload",
        "rocket",
        "time",
        "success",
        "tweetID",
      ],
      jsonURL: "https://watcher-3eeb5-default-rtdb.firebaseio.com/launchlog",
      jsonUpcomingURL:
        "https://watcher-3eeb5-default-rtdb.firebaseio.com/upcoming",
      jsonLaunchUpdatesURL: "https://cnsawatcher.com/json/launch_updates",
      twitterURL: "https://twitter.com/CNSAWatcher/status/",
      headers: [
        { text: "Date", value: "date", align: "left" },
        { text: "Description", value: "description", align: "left" },
        { text: "Launch Site", value: "launchsite", align: "left" },
        { text: "Payload", value: "payload", align: "left" },
        { text: "Rocket", value: "rocket", align: "left" },
        { text: "UTC Time", value: "time", align: "left" },
        { text: "Success", value: "success", align: "center" },
        { text: "Video", value: "tweetID", align: "center" },
      ],
      headersUpcoming: [
        { text: "Date", value: "date" },
        { text: "Description", value: "description" },
        { text: "Launch Site", value: "launchsite" },
        { text: "Payload", value: "payload" },
        { text: "Rocket", value: "rocket" },
        { text: "UTC Time", value: "time" },
        { text: "Video", value: "tweetID" },
      ],
      dataList: {},
      dataListUpcoming: {},
      dataListValues: [],
      dataListValuesUpcoming: [],
      carousel: [],
      loadingUpcoming: true,
      search: "",
      loading: true,
      this_year: new Date().getFullYear(),
      launch_this_year: 0,
      fail_this_year: 0,
    };
  },
  //   computed: {

  //   },

  async created() {
    this.$gtag.event("launch", { method: "Google" });
    if (this.$route.query.tab == "upcoming-launches") {
      this.tab = "upcoming-launches";
    }
    await this.initialize();
    // console.log(this.this_year);
    await this.getUpcomingStream();
  },
  methods: {
    async initialize() {
      //   for (let attr of this.attrs) {
      //     // console.log("h", attr);
      //     // this.newItemToAdd[attr] = "";
      //     // this.editedItem[attr] = "";
      //     this.headers.push({
      //       text: attr == 'tweetID' ? 'Video' : attr,
      //       value: attr,
      //     });
      //     // console.log('header', this.headers);
      //   }
      //   this.headers.push({ text: "Actions", value: "actions", sortable: false });
      // console.log("header", this.headers);
      // console.log(this.newItemToAdd)
      await this.getDataUpcoming();
      await this.getData();
      await this.getCarousel();
      // console.log(this.dataList)
    },
    getData: async function () {
      this.launch_this_year = 0;
      this.fail_this_year = 0;
      let time = new Date().getTime();
      let timeLaunch = localStorage.getItem("timeLaunch");
      if (timeLaunch != null && time - timeLaunch < 1000 * 60 * 1) {
        this.dataList = JSON.parse(localStorage.getItem("dataList"));
      } else {
        const response = await fetch(this.jsonURL + ".json");
        if (response.ok) {
          this.dataList = await response.json();
        } else {
          return;
        }
      }

      let result = [];
      for (let key in this.dataList) {
        let element = {};
        for (let key_element in this.dataList[key]) {
          element[key_element] = this.dataList[key][key_element];
        }
        element["remote_id"] = key;
        element["success"] = "✓";
        if (element["date"].includes(this.this_year)) {
          this.launch_this_year++;
          if (element["description"].toLowerCase().includes("fail")) {
            this.fail_this_year++;
            element["success"] = "✗";
          }
        } else {
          if (element["description"].toLowerCase().includes("fail")) {
            element["success"] = "✗";
          }
        }

        result.push(element);
      }
      this.dataListValues = result;
      this.loading = false;
      localStorage.setItem("dataList", JSON.stringify(this.dataList));
      localStorage.setItem("timeLaunch", new Date().getTime());
    },
    getDataUpcoming: async function () {
      let time = new Date().getTime();
      let timeUpcoming = localStorage.getItem("timeUpcoming");
      if (timeUpcoming != null && time - timeUpcoming < 1000 * 60 * 0) {
        this.dataListUpcoming = JSON.parse(
          localStorage.getItem("dataListUpcoming")
        );
        this.dataListValuesUpcoming = JSON.parse(
          localStorage.getItem("dataListValuesUpcoming")
        );
        this.loadingUpcoming = false;
        return;
      }

      const response = await fetch(this.jsonUpcomingURL + ".json");
      if (response.ok) {
        this.dataListUpcoming = await response.json();
      } else {
        return;
      }
      let result = [];
      for (let key in this.dataListUpcoming) {
        if (this.dataListUpcoming[key]["date"] < this.getUTCLastDate()) {
          continue;
        }
        let element = {};
        for (let key_element in this.dataListUpcoming[key]) {
          element[key_element] = this.dataListUpcoming[key][key_element];
        }
        element["remote_id"] = key;
        result.push(element);
      }
      this.dataListValuesUpcoming = result;
      this.loadingUpcoming = false;

      localStorage.setItem(
        "dataListValuesUpcoming",
        JSON.stringify(this.dataListValuesUpcoming)
      );
      localStorage.setItem(
        "dataListUpcoming",
        JSON.stringify(this.dataListUpcoming)
      );
      localStorage.setItem("timeUpcoming", new Date().getTime());
    },
    async getCarousel() {
      // await this.getData();
      this.carousel = [];
      let carousel = [];
      this.dataListValues.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      let launchUpdates = await this.getLaunchUpdatesJson();
      let dictLaunchUpdates = {};
      for (let key in launchUpdates) {
        dictLaunchUpdates[launchUpdates[key].id] = launchUpdates[key].thumbnail;
      }
      for (let i = 0; i < this.dataListValues.length; i++) {
        let element = this.dataListValues[i];
        if (
          dictLaunchUpdates[element["tweetID"]] != undefined &&
          +dictLaunchUpdates[element["tweetID"]] != -1
        ) {
          carousel.push({
            src: dictLaunchUpdates[element["tweetID"]],
            description: element["description"],
            tweetID: element["tweetID"],
            date: element["date"],
          });
          if (carousel.length >= 5) {
            this.carousel = carousel;
            return;
          }
        }
      }
    },
    async getUpcomingStream() {
      await this.getDataUpcoming();
      // console.log(this.dataListValuesUpcoming);
      if (this.dataListValuesUpcoming.length > 0) {
        this.dataListValuesUpcoming.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });
        for (let i = 0; i < this.dataListValuesUpcoming.length; i++) {
          if (
            this.dataListValuesUpcoming[i].date >= this.getUTCLastDate() &&
            this.dataListValuesUpcoming[i].youtubeID != ""
          ) {
            this.upcomingStream = this.dataListValuesUpcoming[i].youtubeID;
            return;
          }
        }
      }
    },
    // getUTCDate() {
    //   //get date in yyyy-mm-dd format
    //   let day = new Date();
    //   return (
    //     day.getUTCFullYear() +
    //     "-" +
    //     ("00" + (day.getUTCMonth() + 1)).slice(-2) +
    //     "-" +
    //     ("00" + day.getUTCDate()).slice(-2)
    //   );
    // },
    getUTCLastDate() {
      let day = new Date();
      day.setDate(day.getDate() - 1);
      return (
        day.getUTCFullYear() +
        "-" +
        ("00" + (day.getUTCMonth() + 1)).slice(-2) +
        "-" +
        ("00" + day.getUTCDate()).slice(-2)
      );
    },
    async getLaunchUpdatesJson() {
      let time = new Date().getTime();
      let timeLaunchUpdate = localStorage.getItem("timeLaunchUpdate");
      if (timeLaunchUpdate != null && time - timeLaunchUpdate < 1000 * 60 * 1) {
        return JSON.parse(localStorage.getItem("launchUpdates"));
      }
      const response = await fetch(this.jsonLaunchUpdatesURL + ".json");
      if (response.ok) {
        let launchUpdates = await response.json();
        // console.log(launchUpdates);
        // for (let key in launchUpdates) {
        //   console.log(key, launchUpdates[key]);
        // }
        // sort by date
        localStorage.setItem("launchUpdates", JSON.stringify(launchUpdates));
        localStorage.setItem("timeLaunchUpdate", new Date().getTime());
        return launchUpdates;
      } else {
        console.log(response);
      }
    },
    onClickOutside() {
      this.overlay = !this.overlay;
      this.loadingMedia = true;
      // parse string to date
    },

    setRowStyle() {
      /* Here you can also do some validation
      in case you want to apply different css 
      classes depending on item attributes 
      values */
      return "style-1";
    },
  },
};
</script>

<style>
.style-1 td {
  /* height: 100px !important; */
  padding: 10px !important;
}
</style>