<template>
  <v-card>
    <v-btn @click="logout">Logout</v-btn>
    <!-- <v-btn @click="test">test</v-btn> -->
    <v-card>
      <v-btn to="/admin/test">test</v-btn>
      <v-btn to="/admin/launchlog">Launch Log</v-btn>
      <v-btn to="/admin/upcoming">Upcoming Log</v-btn>
    </v-card>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="dataListValues"
      :search="search"
      item-key="remote_id"
      :sort-by="['date', 'time']"
      :sort-desc="[true, true]"
      class="elevation-1"
      show-select
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- <v-toolbar-title>My CRUD</v-toolbar-title> -->
          <!-- <v-spacer></v-spacer> -->
          <!-- <v-btn color="error" @click="deleteSelected"
            >Delete Selected Items</v-btn
          > -->
          <v-dialog v-model="dialogDeleteSelected" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" dark class="mb-2" v-bind="attrs" v-on="on">
                Delete Selected Items
              </v-btn>
            </template>
            <v-card>
              <v-card-title v-if="selected.length > 0">
                Delete selected items permanently?
              </v-card-title>
              <v-card-title v-else> No item selected. </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDeleteSelected"
                  >Cancel</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="deleteSelectedItemsConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
          <!-- <v-spacer></v-spacer> -->
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Add New Item
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span v-if="editedIndex > -1" class="text-h5">Edit Item</span>
                <span v-else class="text-h5">Add New Item</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="(val, key) in editedItem"
                      :key="key"
                      cols="12"
                    >
                      <p>{{ key }}</p>
                      <v-text-field v-model="editedItem[key]"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  v-if="editedIndex > -1"
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
                <v-btn v-else color="blue darken-1" text @click="save">
                  Add
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title> Delete this item permanently? </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>


<script>
// import CardItem from "./CardItem.vue";
export default {
  props: ["attrs", "jsonURL"],
  // components: { CardItem },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      dialogDeleteSelected: false,
      editedIndex: -1,
      // editedItem: {
      //   name: "",
      //   calories: 0,
      //   fat: 0,
      //   carbs: 0,
      //   protein: 0,
      // },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      search: "",
      selected: [],
      headers: [
        // {
        //   text: 'Dessert (100g serving)',
        //   align: 'start',
        //   sortable: false,
        //   value: 'name',
        // },
        // { text: 'Calories', value: 'calories' },
        // { text: 'Fat (g)', value: 'fat' },
        // { text: 'Carbs (g)', value: 'carbs' },
        // { text: 'Protein (g)', value: 'protein' },
        // { text: 'Iron (%)', value: 'iron' },
      ],
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%",
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: "1%",
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: "7%",
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: "8%",
        },
        {
          name: "Gingerbread",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: "16%",
        },
        {
          name: "Jelly bean",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: "0%",
        },
        {
          name: "Lollipop",
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: "2%",
        },
        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: "45%",
        },
        {
          name: "Donut",
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: "22%",
        },
        {
          name: "KitKat",
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: "6%",
        },
      ],
      dataList: {},
      // newItemToAdd: {},
      editedItem: {},
    };
  },

  computed: {
    dataListValues() {
      // console.log(this.dataList)
      // console.log(this.desserts)
      let result = [];
      for (let key in this.dataList) {
        // console.log(i);
        // result.push(this.dataList[key]);
        // result[result.length - 1]['remote_id'] = key;
        // console.log(-1, result[result.length - 1]);
        let element = {};
        for (let key_element in this.dataList[key]) {
          element[key_element] = this.dataList[key][key_element];
        }
        element["remote_id"] = key;
        result.push(element);
      }
      // console.log(result);
      // console.log(this.desserts);
      return result;
    },
  },

  created() {
    // console.log(this.$store.getters['isAuthenticated']);
    if (!this.$store.getters["isAuthenticated"]) {
      this.$router.replace("/admin/login");
      return;
    }
    this.initialize();
  },
  methods: {
    initialize() {
      for (let attr of this.attrs) {
        // console.log("h", attr);
        // this.newItemToAdd[attr] = "";
        this.editedItem[attr] = "";
        this.headers.push({
          text: attr,
          value: attr,
        });
        // console.log('header', this.headers);
      }
      this.headers.push({ text: "Actions", value: "actions", sortable: false });
      // console.log("header", this.headers);
      // console.log(this.newItemToAdd)
      this.getData();
      // console.log(this.dataList)
    },
    addData: async function (newItemToAdd) {
      const response = await fetch(
        this.jsonURL + ".json?auth=" + this.$store.getters["token"],
        {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          //   "x-access-token": "token-value",
          // },
          body: JSON.stringify(newItemToAdd),
        }
      );
      if (response.ok) {
        this.getData();
      }
    },
    getData: async function () {
      // console.log(this.newItemToAdd);
      const response = await fetch(this.jsonURL + ".json");
      if (response.ok) {
        this.dataList = await response.json();
      }
    },
    deleteData: async function (id) {
      // console.log("deleting");
      const response = await fetch(
        this.jsonURL + "/" + id + ".json?auth=" + this.$store.getters["token"],
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        this.getData();
      } else {
        console.log(response);
      }
    },
    updateData: async function (event, id) {
      // console.log(event, id)
      const response = await fetch(
        this.jsonURL + "/" + id + ".json?auth=" + this.$store.getters["token"],
        {
          method: "PUT",
          body: JSON.stringify(event),
        }
      );
      if (response.ok) {
        // console.log(event);
        this.getData();
      } else {
        console.log(response); // change to alert...
      }
    },
    save() {
      // console.log(this.dataListValues[idx]);
      if (this.editedIndex > -1) {
        // edit & update mode
        let idx = this.editedIndex;
        let obj_to_server = {};
        for (let key in this.editedItem) {
          if (key !== "remote_id") {
            obj_to_server[key] = this.editedItem[key];
            // this.dataListValues[idx][key]
          }
        }
        this.updateData(obj_to_server, this.dataListValues[idx]["remote_id"]);
      } else {
        // add new item mode
        this.addData(this.editedItem);
      }
      // console.log(obj_to_server);
      this.close();
    },
    editItem(item) {
      // console.log(item);
      this.editedIndex = this.dataListValues.indexOf(item);
      for (let key in this.editedItem) {
        this.editedItem[key] = item[key];
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;

      for (let key in this.editedItem) {
        this.editedItem[key] = "";
      }
      this.editedIndex = -1;
    },
    deleteItem(item) {
      this.editedIndex = this.dataListValues.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // console.log(item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // this.desserts.splice(this.editedIndex, 1);
      this.deleteData(this.dataListValues[this.editedIndex]["remote_id"]);
      this.closeDelete();
    },

    closeDelete() {
      this.editedindex = -1;
      this.dialogDelete = false;
    },

    closeDeleteSelected() {
      this.dialogDeleteSelected = false;
    },

    // showSelected() {
    //   console.log(this.selected);
    // },

    deleteSelectedItemsConfirm() {
      for (let item of this.selected) {
        // console.log(item);
        this.deleteData(item["remote_id"]);
      }
      this.selected = [];
      this.closeDeleteSelected();
    },

    // test() {
    //   this.$store.commit('increment');
    //   console.log(this.$store.getters['count']);
    // },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/admin/login");
    },
  },
};
</script>

<style scoped>
.margin {
  margin: 0% 10% 0 10%;
  /* text-align: center; */
  /* background: red; */
  /* margin-left: auto;
  margin-right: auto; */
}
.center {
  /* text-align: center; */
  /* align-items: center; */
}

table {
  /* margin: 0% 10% 0 10%; */
  /* border-style: dotted; */
  /* margin-left: auto;
  margin-right: auto; */
  width: 100%;
  /* background: rgb(247, 240, 227); */
  text-align: center;
  border-collapse: collapse;

  /* white-space: nowrap; */
}

/* th {
  padding: 10px;
  border: solid;
  border-width: 1px;
  border-color: rgb(200, 200, 200);
  max-width: 10%;
} */
</style>

<style>
.th-manage:nth-child(odd),
.td-manage:nth-child(odd) {
  background-color: rgb(230, 230, 230);
}

.th-manage,
.td-manage {
  padding: 10px;
  border: solid;
  border-width: 1px;
  border-color: rgb(200, 200, 200);
  max-width: 10%;
}
</style>