var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-btn',{on:{"click":_vm.logout}},[_vm._v("Logout")]),_c('v-card',[_c('v-btn',{attrs:{"to":"/admin/test"}},[_vm._v("test")]),_c('v-btn',{attrs:{"to":"/admin/launchlog"}},[_vm._v("Launch Log")]),_c('v-btn',{attrs:{"to":"/admin/upcoming"}},[_vm._v("Upcoming Log")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataListValues,"search":_vm.search,"item-key":"remote_id","sort-by":['date', 'time'],"sort-desc":[true, true],"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"error","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Delete Selected Items ")])]}}]),model:{value:(_vm.dialogDeleteSelected),callback:function ($$v) {_vm.dialogDeleteSelected=$$v},expression:"dialogDeleteSelected"}},[_c('v-card',[(_vm.selected.length > 0)?_c('v-card-title',[_vm._v(" Delete selected items permanently? ")]):_c('v-card-title',[_vm._v(" No item selected. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDeleteSelected}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteSelectedItemsConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Add New Item ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[(_vm.editedIndex > -1)?_c('span',{staticClass:"text-h5"},[_vm._v("Edit Item")]):_c('span',{staticClass:"text-h5"},[_vm._v("Add New Item")])]),_c('v-card-text',[_c('v-container',[_c('v-row',_vm._l((_vm.editedItem),function(val,key){return _c('v-col',{key:key,attrs:{"cols":"12"}},[_c('p',[_vm._v(_vm._s(key))]),_c('v-text-field',{model:{value:(_vm.editedItem[key]),callback:function ($$v) {_vm.$set(_vm.editedItem, key, $$v)},expression:"editedItem[key]"}})],1)}),1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),(_vm.editedIndex > -1)?_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")]):_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Add ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Delete this item permanently? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }