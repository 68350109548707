<template>
  <v-container fluid grid-list-md>
    <v-overlay v-if="selectedItemIndex >= 0 && overlay" :value="overlay">
      <v-card style="padding-top: 20px" v-click-outside="onClickOutside">
        <v-icon
          style="padding-left: 50px; padding-bottom: 10px"
          @click="
            overlay = !overlay;
            loadingMedia = true;
          "
          >mdi-close</v-icon
        >
        <br />
        <div v-if="tab == 'gallery'">
          <div style="text-align: center">
            <v-progress-circular
              indeterminate
              dark
              v-if="loadingMedia"
            ></v-progress-circular>
            <img
              :src="
                rootURL +
                dataListGalleryCurrentPage[selectedItemIndex].href_compressed
              "
              @load="
                (e) => {
                  loadingMedia = false;
                  e.target.style.height = 'auto';
                  e.target.style.width = 'auto';
                  e.target.style.maxWidth = '90vw';
                  e.target.style.maxHeight = '80vh';
                }
              "
            />
            <v-card-subtitle>
              {{ dataListGalleryCurrentPage[selectedItemIndex].title }}<br />{{
                parseDate(dataListGalleryCurrentPage[selectedItemIndex].date)
              }}
            </v-card-subtitle>
          </div>
        </div>
        <div v-else-if="tab == 'videos'">
          <div style="text-align: center">
            <iframe
              :src="
                'https://www.youtube.com/embed/' +
                dataListVideoCurrentPage[selectedItemIndex].videoID +
                '?autoplay=1'
              "
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              style="width: 90vw; height: 75vh"
            ></iframe>
          </div>
          <v-card-subtitle>
            {{ dataListVideoCurrentPage[selectedItemIndex].title }}<br />{{
              parseDate(dataListVideoCurrentPage[selectedItemIndex].date)
            }}
          </v-card-subtitle>
        </div>
        <div v-else-if="tab == 'news'">
          <div
            style="
              text-align: center;
              max-height: 80vh;
              width: 80vw;
              max-width: 480px;
              overflow-y: auto;
            "
          >
            <!-- <img
              :src="
                dataListUpdatesCurrentPage[selectedItemIndex].thumbnail
              "
              @load="
                (e) => {
                  loadingMedia = false;
                  e.target.style.height = 'auto';
                  e.target.style.width = 'auto';
                  e.target.style.maxWidth = '90vw';
                  e.target.style.maxHeight = '80vh';
                }
              "
            /> -->
            <Tweet
              :id="dataListUpdatesCurrentPage[selectedItemIndex].id"
              :options="{ theme: 'dark' }"
              ><v-progress-circular indeterminate dark></v-progress-circular
            ></Tweet>
            <!-- <v-card-subtitle>
              {{ dataListUpdatesCurrentPage[selectedItemIndex].tweet }}<br />{{
                parseDate(dataListUpdatesCurrentPage[selectedItemIndex].date)
              }}
            </v-card-subtitle> -->
          </div>
        </div>
      </v-card>
    </v-overlay>
    <v-tabs dark color="white" background-color="black" centered v-model="tab">
      <v-tab href="#gallery">Gallery</v-tab>
      <v-tab href="#videos">Videos</v-tab>
      <v-tab href="#news">News Feed</v-tab>
    </v-tabs>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        dark
      ></v-text-field>
    </v-card-title>
    <!-- xs = 600px full screen (12) -->
    <!-- md = 600px or more. half of the screen (6) -->
    <v-tabs-items v-model="tab" style="background-color: black">
      <v-tab-item value="gallery">
        <v-layout row wrap>
          <v-flex
            xs6
            md4
            lg3
            v-for="(item, ind) in dataListGalleryCurrentPage"
            :key="item.href"
          >
            <v-card>
              <v-img
                :src="rootURL + item.href_avatar"
                height="256px"
                @click="
                  selectedItemIndex = ind;
                  overlay = !overlay;
                "
              ></v-img>
            </v-card>
          </v-flex>
        </v-layout>
        <div class="text-center;">
          <v-pagination
            v-model="currentPageGallery"
            :length="numberOfPagesGallery"
            :total-visible="7"
            circle
            dark
            style="color: white"
          ></v-pagination>
        </div>
      </v-tab-item>

      <v-tab-item value="videos">
        <v-layout row wrap>
          <v-flex
            xs12
            md6
            lg4
            v-for="(item, ind) in dataListVideoCurrentPage"
            :key="item.videoID"
          >
            <v-card style="margin: 10px">
              <v-img
                :src="
                  'https://img.youtube.com/vi/' +
                  item.videoID +
                  '/hqdefault.jpg'
                "
                height="300px"
                @click="
                  selectedItemIndex = ind;
                  overlay = !overlay;
                "
                ><div
                  class="text-h7"
                  style="
                    background-color: rgba(10, 10, 10, 0.5);
                    padding-left: 10px;
                    padding-right: 10px;
                  "
                >
                  <p style="color: #dddddd">{{ parseDate(item.date) }}</p>
                  <p style="color: white">{{ item.title }}</p>
                </div></v-img
              >
            </v-card>
          </v-flex>
        </v-layout>
        <div class="text-center;">
          <v-pagination
            v-model="currentPageVideo"
            :length="numberOfPagesVideo"
            :total-visible="7"
            circle
            dark
            style="color: white"
          ></v-pagination>
        </div>
      </v-tab-item>
      <v-tab-item value="news">
        <!-- <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              dark
            ></v-text-field>
          </v-card-title> -->
        <!-- <v-data-table
          dark
          style="background-color: black; "
          :items="dataListUpdates"
          :headers="headerUpdates"
          :sort-by="['date']"
          :sort-desc="[true]"
          :search="search"
          hide-default-header
          :item-class="setRowStyle"
        >
        <template v-slot:[`item.thumbnail`]="{ item }">
          <v-img :src="item.thumbnail == '' ? require('../assets/img/default_thumbnail.jpeg'):item.thumbnail" height="300px" width="88vw" max-width="600px"></v-img>
      </template>
        </v-data-table> -->
        <v-layout row wrap>
          <v-flex
            xs12
            md6
            lg6
            v-for="(item, ind) in dataListUpdatesCurrentPage"
            :key="item.link"
          >
            <v-card
              dark
              @click="
                selectedItemIndex = ind;
                overlay = !overlay;
              "
            >
              <v-img
                :src="
                  item.thumbnail == ''
                    ? require('../assets/img/default_thumbnail.jpeg')
                    : item.thumbnail
                "
                height="350px"
              ></v-img>
              <div style="padding: 10px">
                <p style="color: #dddddd">
                  {{ parseDate(item.date) }}
                </p>
                <p style="color: white">
                  {{ item.tweet }}
                </p>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
        <div class="text-center;">
          <v-pagination
            v-model="currentPageUpdates"
            :length="numberOfPagesUpdates"
            :total-visible="7"
            circle
            dark
            style="color: white"
          ></v-pagination>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { Tweet } from "vue-tweet-embed";
export default {
  components: {
    Tweet,
  },
  props: [
    "page_name",
    "rootURL",
    "jsonURLGallery",
    "jsonURLVideo",
    "jsonURLUpdates",
  ],
  data() {
    return {
      tab: null,
      loading: true,
      loadingMedia: true,
      search: "",
      dataListGallery: [],
      dataListSearchGallery: [],
      dataListGalleryCurrentPage: [],
      dataListVideo: [],
      dataListSearchVideo: [],
      dataListVideoCurrentPage: [],
      dataListUpdates: [],
      dataListSearchUpdates: [],
      dataListUpdatesCurrentPage: [],
      // headerUpdates: [
      //   {
      //     text: "thumbnail",
      //     value: "thumbnail",
      //     align: "left",
      //   },
      //   {
      //     text: "Date",
      //     value: "date",
      //     align: "left",
      //   },
      //   {
      //     text: "Tweet",
      //     value: "tweet",
      //     align: "left",
      //   },

      // ],
      currentPageGallery: 1,
      numberPerPageGallery: 24,
      currentPageVideo: 1,
      numberPerPageVideo: 24,
      currentPageUpdates: 1,
      numberPerPageUpdates: 10,
      selectedItemIndex: -1,
      overlay: false,
    };
  },
  created() {
    this.getGallery();
    this.getVideos();
    this.getUpdates();
  },
  computed: {
    numberOfPagesGallery() {
      return Math.ceil(
        this.dataListSearchGallery.length / this.numberPerPageGallery
      );
    },
    numberOfPagesVideo() {
      return Math.ceil(
        this.dataListSearchVideo.length / this.numberPerPageVideo
      );
    },
    numberOfPagesUpdates() {
      return Math.ceil(
        this.dataListSearchUpdates.length / this.numberPerPageUpdates
      );
    },
  },
  watch: {
    currentPageGallery() {
      this.getGallery();
    },
    currentPageVideo() {
      this.getVideos();
    },
    currentPageUpdates() {
      this.getUpdates();
    },
    search() {
      this.getGallery();
      this.getVideos();
      this.getUpdates();
    },
  },
  methods: {
    filterItem(item) {
      if (item == null) {
        return false;
      }
      for (let key in item) {
        if (
          item[key]
            .toString()
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -1
        ) {
          return true;
        }
      }
      return false;
    },
    filterSearch() {
      if (this.search.length > 0) {
        this.dataListSearchGallery = this.dataListGallery.filter(
          this.filterItem
        );
        this.dataListSearchVideo = this.dataListVideo.filter(this.filterItem);
        this.dataListSearchUpdates = this.dataListUpdates.filter(
          this.filterItem
        );
      } else {
        this.dataListSearchGallery = this.dataListGallery;
        this.dataListSearchVideo = this.dataListVideo;
        this.dataListSearchUpdates = this.dataListUpdates;
      }
    },
    async getGallery() {
      let time = new Date().getTime();
      let timeGallery = localStorage.getItem(
        "time" + this.page_name + "Gallery"
      );
      if (timeGallery != null && time - timeGallery < 1000 * 60 * 1) {
        this.dataListGallery = JSON.parse(
          localStorage.getItem("dataList" + this.page_name + "Gallery")
        );
        this.filterSearch();
        this.dataListGalleryCurrentPage = this.dataListSearchGallery.slice(
          (this.currentPageGallery - 1) * this.numberPerPageGallery,
          this.currentPageGallery * this.numberPerPageGallery
        );
        this.loading = false;
        return;
      }
      const response = await fetch(this.jsonURLGallery + ".json");
      if (response.ok) {
        this.dataListGallery = await response.json();
        this.dataListGallery.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });
        this.filterSearch();
        this.dataListGalleryCurrentPage = this.dataListSearchGallery.slice(
          (this.currentPageGallery - 1) * this.numberPerPageGallery,
          this.currentPageGallery * this.numberPerPageGallery
        );
      } else {
        return;
      }
      this.loading = false;
      localStorage.setItem(
        "dataList" + this.page_name + "Gallery",
        JSON.stringify(this.dataListGallery)
      );
      localStorage.setItem(
        "time" + this.page_name + "Gallery",
        new Date().getTime()
      );
      // console.log(this.dataListGallery);
    },
    async getVideos() {
      let time = new Date().getTime();
      let timeVideo = localStorage.getItem("time" + this.page_name + "Video");
      if (timeVideo != null && time - timeVideo < 1000 * 60 * 1) {
        this.dataListVideo = JSON.parse(
          localStorage.getItem("dataList" + this.page_name + "Video")
        );
        this.filterSearch();
        this.dataListVideoCurrentPage = this.dataListSearchVideo.slice(
          (this.currentPageVideo - 1) * this.numberPerPageVideo,
          this.currentPageVideo * this.numberPerPageVideo
        );
        this.loading = false;
        return;
      }
      const response = await fetch(this.jsonURLVideo + ".json");
      if (response.ok) {
        this.dataListVideo = await response.json();
        this.dataListVideo.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });
        this.filterSearch();
        this.dataListVideoCurrentPage = this.dataListSearchVideo.slice(
          (this.currentPageVideo - 1) * this.numberPerPageVideo,
          this.currentPageVideo * this.numberPerPageVideo
        );
      } else {
        return;
      }
      this.loading = false;
      localStorage.setItem(
        "dataList" + this.page_name + "Video",
        JSON.stringify(this.dataListVideo)
      );
      localStorage.setItem(
        "time" + this.page_name + "Video",
        new Date().getTime()
      );
      // console.log(this.dataListVideo);
    },

    async getUpdates() {
      let time = new Date().getTime();
      let timeUpdates = localStorage.getItem(
        "time" + this.page_name + "Updates"
      );
      if (timeUpdates != null && time - timeUpdates < 1000 * 60 * 1) {
        this.dataListUpdates = JSON.parse(
          localStorage.getItem("dataList" + this.page_name + "Updates")
        );
        this.filterSearch();
        this.dataListUpdatesCurrentPage = this.dataListSearchUpdates.slice(
          (this.currentPageUpdates - 1) * this.numberPerPageUpdates,
          this.currentPageUpdates * this.numberPerPageUpdates
        );
        this.loading = false;
        return;
      }
      const response = await fetch(this.jsonURLUpdates + ".json");
      if (response.ok) {
        this.dataListUpdates = await response.json();
        this.dataListUpdates.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        this.filterSearch();
        this.dataListUpdatesCurrentPage = this.dataListSearchUpdates.slice(
          (this.currentPageUpdates - 1) * this.numberPerPageUpdates,
          this.currentPageUpdates * this.numberPerPageUpdates
        );
      } else {
        return;
      }
      this.loading = false;
      localStorage.setItem(
        "dataList" + this.page_name + "Updates",
        JSON.stringify(this.dataListUpdates)
      );
      localStorage.setItem(
        "time" + this.page_name + "Updates",
        new Date().getTime()
      );
      // console.log(this.dataListUpdates);
    },
    onClickOutside() {
      this.overlay = !this.overlay;
      this.loadingMedia = true;
      // parse string to date
    },
    parseDate(date) {
      if (date.length == 8) {
        return (
          date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6, 8)
        );
      }
      return date;
    },
  },
};
</script>