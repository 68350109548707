<template>
  <v-app>
    <v-main style="background: black;">
      <!-- <HelloWorld/> -->
      <header-component></header-component>
      <router-view></router-view>
      <footer-component></footer-component>
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

// export default {
//   name: 'App',

//   // components: {
//   //   HelloWorld,
//   // },

//   data: () => ({
//     //
//   }),
// }
//
</script>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
export default {
  components: {
    HeaderComponent,
    FooterComponent
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
  },
  created() {
    // console.log('tryLogin-ing')
    this.$store.dispatch("tryLogin");
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace("/login");
      }
    },
  },
};
</script>

<style>
html,body{
    overflow-x: hidden;
}
</style>