<template>
  <content-template
    :page_name="page_name"
    :rootURL="rootURL"
    :jsonURLGallery="jsonURLGallery"
    :jsonURLVideo="jsonURLVideo"
    :jsonURLUpdates="jsonURLUpdates"
  ></content-template>
</template>

<script>
import ContentTemplate from "../components/ContentTemplate.vue";
export default {
  components: {
    ContentTemplate,
  },
  created() {
    this.$gtag.event("moon", { method: "Google" });
  },
  data() {
    return {
      rootURL: "https://cnsawatcher.com/",
      jsonURLGallery: "https://cnsawatcher.com/json/yutu_gallery",
      jsonURLVideo: "https://cnsawatcher.com/json/yutu_gallery_videos",
      jsonURLUpdates: "https://cnsawatcher.com/json/moon_updates",
      page_name: "Moon",
    };
  },
};
</script>