import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import Vuex from 'vuex';

import HomePage from './pages/HomePage.vue'
import LaunchLog from './pages/admin/LaunchLog.vue'
import TestPage from './pages/admin/TestPage.vue'
import AuthPage from './pages/admin/AuthPage.vue'
import UpcomingLog from './pages/admin/UpcomingLog.vue'
import LaunchPage from './pages/LaunchPage.vue'
import SpacestationPage from './pages/SpacestationPage.vue'
import MarsPage from './pages/MarsPage.vue'
import MoonPage from './pages/MoonPage.vue'
import AboutPage from './pages/AboutPage.vue'
import store from './store.js'
// import VueAnalytics from 'vue-analytics'
import VueGtag from "vue-gtag";


Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.config.devtools = false



const routes = [
  {
    path: '/',
    component: HomePage,
    name: 'CNSA Watcher',
  },
  {
    path: '/launches',
    component: LaunchPage,
  },
  {
    path: '/space-station',
    component: SpacestationPage,
    name: 'CNSA Watcher - Space Station',
  },
  {
    path: '/mars-rover',
    component: MarsPage,
    name: 'CNSA Watcher - Mars Rover',
  },
  {
    path: '/moon-rover',
    component: MoonPage,
    name: 'CNSA Watcher - Moon Rover',
  },
  {
    path: '/about',
    component: AboutPage,
    name: 'CNSA Watcher - About',
  },
  {
    path: '/admin/test',
    component: TestPage,
    name: 'CNSA Watcher - Test',
  },
  {
    path: '/admin/launchlog',
    component: LaunchLog,
    name: 'CNSA Watcher - Launch Log',
  },
  {
    path: '/admin/upcoming',
    component: UpcomingLog,
    name: 'CNSA Watcher - Upcoming',
  },
  {
    path: '/admin/login',
    component: AuthPage,
    name: 'CNSA Watcher - Login',
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
})
router.beforeEach((to, from, next) => {
  document.title = to.name != undefined ? to.name : 'CNSA Watcher';
  next();
});
// Vue.use(VueAnalytics, {
//   id: 'G-M9PL8DW1CD',
//   router: router
// })
Vue.use(VueGtag, {
  config: { id: "G-M9PL8DW1CD" }
});
const app = new Vue({
  store: new Vuex.Store(store),
  router: router,
  vuetify,

  render: h => h(App)
});

app.$mount('#app')
