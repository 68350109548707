<template>
  <json-communicate :attrs="attrs" :jsonURL="jsonURL"></json-communicate>
</template>

<script>
import JsonCommunicate from '../../components/JsonCommunicate.vue'
export default {
  components: {
    JsonCommunicate
  },
  data() {
    return {
      attrs: ['date', 'description', 'launchsite', 'payload', 'rocket', 'time', 'tweetID'],
      jsonURL: "https://watcher-3eeb5-default-rtdb.firebaseio.com/launchlog",
    }
  }
}
</script>