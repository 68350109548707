<template>
  <json-communicate :attrs="attrs" :jsonURL="jsonURL"></json-communicate>
</template>

<script>
import JsonCommunicate from '../../components/JsonCommunicate.vue'
export default {
  components: {
    JsonCommunicate
  },
  data() {
    return {
      attrs: ['name', 'description'],
      jsonURL: "https://watcher-3eeb5-default-rtdb.firebaseio.com/test",
    }
  }
}
</script>