var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[(_vm.selectedItemIndex >= 0 && _vm.overlay)?_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticStyle:{"padding-top":"20px"}},[_c('v-icon',{staticStyle:{"padding-left":"50px","padding-bottom":"10px"},on:{"click":function($event){_vm.overlay = !_vm.overlay;
          _vm.loadingMedia = true;}}},[_vm._v("mdi-close")]),_c('br'),(_vm.tab == 'gallery')?_c('div',[_c('div',{staticStyle:{"text-align":"center"}},[(_vm.loadingMedia)?_c('v-progress-circular',{attrs:{"indeterminate":"","dark":""}}):_vm._e(),_c('img',{attrs:{"src":_vm.rootURL +
              _vm.dataListGalleryCurrentPage[_vm.selectedItemIndex].href_compressed},on:{"load":function (e) {
                _vm.loadingMedia = false;
                e.target.style.height = 'auto';
                e.target.style.width = 'auto';
                e.target.style.maxWidth = '90vw';
                e.target.style.maxHeight = '80vh';
              }}}),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.dataListGalleryCurrentPage[_vm.selectedItemIndex].title)),_c('br'),_vm._v(_vm._s(_vm.parseDate(_vm.dataListGalleryCurrentPage[_vm.selectedItemIndex].date))+" ")])],1)]):(_vm.tab == 'videos')?_c('div',[_c('div',{staticStyle:{"text-align":"center"}},[_c('iframe',{staticStyle:{"width":"90vw","height":"75vh"},attrs:{"src":'https://www.youtube.com/embed/' +
              _vm.dataListVideoCurrentPage[_vm.selectedItemIndex].videoID +
              '?autoplay=1',"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.dataListVideoCurrentPage[_vm.selectedItemIndex].title)),_c('br'),_vm._v(_vm._s(_vm.parseDate(_vm.dataListVideoCurrentPage[_vm.selectedItemIndex].date))+" ")])],1):(_vm.tab == 'news')?_c('div',[_c('div',{staticStyle:{"text-align":"center","max-height":"80vh","width":"80vw","max-width":"480px","overflow-y":"auto"}},[_c('Tweet',{attrs:{"id":_vm.dataListUpdatesCurrentPage[_vm.selectedItemIndex].id,"options":{ theme: 'dark' }}},[_c('v-progress-circular',{attrs:{"indeterminate":"","dark":""}})],1)],1)]):_vm._e()],1)],1):_vm._e(),_c('v-tabs',{attrs:{"dark":"","color":"white","background-color":"black","centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#gallery"}},[_vm._v("Gallery")]),_c('v-tab',{attrs:{"href":"#videos"}},[_vm._v("Videos")]),_c('v-tab',{attrs:{"href":"#news"}},[_vm._v("News Feed")])],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","dark":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-tabs-items',{staticStyle:{"background-color":"black"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"gallery"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.dataListGalleryCurrentPage),function(item,ind){return _c('v-flex',{key:item.href,attrs:{"xs6":"","md4":"","lg3":""}},[_c('v-card',[_c('v-img',{attrs:{"src":_vm.rootURL + item.href_avatar,"height":"256px"},on:{"click":function($event){_vm.selectedItemIndex = ind;
                _vm.overlay = !_vm.overlay;}}})],1)],1)}),1),_c('div',{staticClass:"text-center;"},[_c('v-pagination',{staticStyle:{"color":"white"},attrs:{"length":_vm.numberOfPagesGallery,"total-visible":7,"circle":"","dark":""},model:{value:(_vm.currentPageGallery),callback:function ($$v) {_vm.currentPageGallery=$$v},expression:"currentPageGallery"}})],1)],1),_c('v-tab-item',{attrs:{"value":"videos"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.dataListVideoCurrentPage),function(item,ind){return _c('v-flex',{key:item.videoID,attrs:{"xs12":"","md6":"","lg4":""}},[_c('v-card',{staticStyle:{"margin":"10px"}},[_c('v-img',{attrs:{"src":'https://img.youtube.com/vi/' +
                item.videoID +
                '/hqdefault.jpg',"height":"300px"},on:{"click":function($event){_vm.selectedItemIndex = ind;
                _vm.overlay = !_vm.overlay;}}},[_c('div',{staticClass:"text-h7",staticStyle:{"background-color":"rgba(10, 10, 10, 0.5)","padding-left":"10px","padding-right":"10px"}},[_c('p',{staticStyle:{"color":"#dddddd"}},[_vm._v(_vm._s(_vm.parseDate(item.date)))]),_c('p',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(item.title))])])])],1)],1)}),1),_c('div',{staticClass:"text-center;"},[_c('v-pagination',{staticStyle:{"color":"white"},attrs:{"length":_vm.numberOfPagesVideo,"total-visible":7,"circle":"","dark":""},model:{value:(_vm.currentPageVideo),callback:function ($$v) {_vm.currentPageVideo=$$v},expression:"currentPageVideo"}})],1)],1),_c('v-tab-item',{attrs:{"value":"news"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.dataListUpdatesCurrentPage),function(item,ind){return _c('v-flex',{key:item.link,attrs:{"xs12":"","md6":"","lg6":""}},[_c('v-card',{attrs:{"dark":""},on:{"click":function($event){_vm.selectedItemIndex = ind;
              _vm.overlay = !_vm.overlay;}}},[_c('v-img',{attrs:{"src":item.thumbnail == ''
                  ? require('../assets/img/default_thumbnail.jpeg')
                  : item.thumbnail,"height":"350px"}}),_c('div',{staticStyle:{"padding":"10px"}},[_c('p',{staticStyle:{"color":"#dddddd"}},[_vm._v(" "+_vm._s(_vm.parseDate(item.date))+" ")]),_c('p',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(item.tweet)+" ")])])],1)],1)}),1),_c('div',{staticClass:"text-center;"},[_c('v-pagination',{staticStyle:{"color":"white"},attrs:{"length":_vm.numberOfPagesUpdates,"total-visible":7,"circle":"","dark":""},model:{value:(_vm.currentPageUpdates),callback:function ($$v) {_vm.currentPageUpdates=$$v},expression:"currentPageUpdates"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }