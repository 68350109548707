<template>
  <v-card
    max-width="1000px"
    class="mx-auto my-12 px-14 py-14 elevation-6"
    outlined
    shaped
    centered
  >
    <v-card-title>Administrator</v-card-title>
    <v-text-field
      label="Email"
      type="email"
      v-model="email"
      @keyup.enter="submitForm"
    >
    </v-text-field>
    <v-text-field
      label="Password"
      type="password"
      v-model="password"
      @keyup.enter="submitForm"
    >
    </v-text-field>
    <v-card-text v-if="!formIsValid">Please enter a valid email and password (must be at least 6 characters long).
    </v-card-text>
    <v-card-text v-if="error">{{ error }}</v-card-text>
    <v-spacer></v-spacer>
    <v-btn @click="submitForm">Login</v-btn>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
        formIsValid: true,
    //   login_info: {
    //     email: "",
    //     password: "",
    //     returnSecureToken: true,
    //   },
        email: "",
        password: "",
        mode: "login",
        error: null,
    };
  },
  created() {
    //   console.log(this.$store.getters['isAuthenticated']);
      if (this.$store.getters['isAuthenticated']) {
          this.$router.replace('/admin/launchlog');
      }
  },
  methods: {
    async submitForm() {
      this.error = null;
      this.formIsValid = true;
      if (
        this.email === '' ||
        !this.email.includes('@') ||
        this.password.length < 6
      ) {
        this.formIsValid = false;
        return;
      }

      this.isLoading = true;

      const actionPayload = {
        email: this.email,
        password: this.password,
      };

      try {
        if (this.mode === 'signup') {
          await this.$store.dispatch('signup', actionPayload);
        } else {
          await this.$store.dispatch('login', actionPayload);
        }
        const redirectUrl = '/admin/launchlog';// + (this.$route.query.redirect || '/');
        this.$router.replace(redirectUrl);
      } catch (err) {
        this.error = err.message || 'Failed to authenticate, try later.';
      }

      this.isLoading = false;
    },
  },
};
</script>