<template>
  <div>
    <!-- <v-app app> -->
    <v-app-bar
      app
      dark
      color="#000000"
      shrink-on-scroll
      fade-img-on-scroll
      src="../assets/img/top_bar.jpg"
      height="200"
    >
    
      <v-app-bar-title
        style="
          margin-left: 27vw;
          margin-right: 5%;
          margin-bottom: -15px;
          text-align: center;
          position: absolute;
        "
        class="text-no-wrap title overflow-visible"
        
        
      >
      <router-link to="/"><img src="../assets/img/agent_logo.png" alt="logo" height="100" /></router-link>
        <router-link to="/" style="text-decoration: none; color: white;"><p style="font-weight: bold; ">CNSA Watcher</p></router-link>
        
        </v-app-bar-title
      >
      <v-spacer></v-spacer>
      <!-- <template v-slot:extension> -->
      <v-tabs v-model="tab" align-with-title class="d-none d-md-flex" style="margin-left: 40vw;">
        <v-tabs-slider color="yellow"></v-tabs-slider>

        <v-tab v-for="item in items" :key="item.text" :to="item.path">
          {{ item.text }}
        </v-tab>
      </v-tabs>
        <!-- </template> -->
      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->

      <!-- <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->

      <!-- <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title class="d-none d-sm-flex">
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </template> -->
      <v-app-bar-nav-icon
        @click="drawer = true"
        class="d-flex d-md-none"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <!-- Add a navigation bar -->

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      dark
      right
      color="#000000"
    >
      <v-list nav dense>
        <v-list-item-group>
          <v-icon @click="drawer = false">mdi-close</v-icon>
          <v-list-item v-for="(item, index) in items" :key="item.text" :to="item.path">
            <v-list-item-title @click="tab = index">{{
              item.text
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      tab: null,
      items: [
        {text: "Launches", path: "/launches"},
        {text: "Space Station", path: "/space-station"},
        {text: "Mars Rover", path: "/mars-rover"},
        {text: "Moon Rover", path: "/moon-rover"},
        {text: "About", path: "/about"},
      ],
    };
  },
  
  created() {
    if (this.$route.query.admin !== undefined) {
      this.$router.push({ path: '/admin/login' });
    }
    window.addEventListener("mousewheel", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("mousewheel", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Any code to be executed when the window is scrolled
      // console.log("scrolling");
      if (this.drawer) {
        this.drawer = false;
      }
    },
  },
};
</script>

<style>
/* .v-app-bar-title__placeholder {
  width: 142px !important;
} */
</style>