<template>
  <!-- create a centered v-tab -->
  <!-- <v-tabs v-model="tab" centered> -->
  <!-- <div>
    <v-toolbar color="indigo" dark fixed app>
        <v-toolbar-title>Toolbar Mobile Menu</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn v-for="item in menu" :key="item.icon" :to="item.link" flat>{{
            item.title
          }}</v-btn>
        </v-toolbar-items>
        <v-menu class="hidden-md-and-up">
          <v-app-bar-nav-icon >
                            <template v-slot:activator="{ on }"><v-btn v-on="on"></v-btn></template>

          </v-app-bar-nav-icon>
          <v-list>
            <v-list-tile v-for="item in menu" :key="item.icon">
              <v-list-tile-content>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar>

    <v-parallax
    height="800"
      src="../assets/img/tiangong_profile.png"
    ></v-parallax>
    <v-parallax
    height="800"
      src="../assets/img/zhurong_profile.png"
    ></v-parallax>
    <v-parallax
    height="800"
      src="../assets/img/launch_profile.jpg"
    ></v-parallax>
    <v-parallax
    height="800"
      src="../assets/img/yutu_profile.jpeg"
    ></v-parallax>

  </div> -->
  <div>
    <!-- Navigation bar ends -->
    <!-- <v-main class="ma-5" fluid> -->
    <!-- <v-tabs-items v-model="tab" class="d-flex flex-column align-center">
        <v-tab-item v-for="item in items" :key="item"> -->
    <!-- <v-sheet
      id="hello"
      class="overflow-y-auto"
      max-height="600"
    > -->
    <div
      v-if="upcoming !== ''"
      style="background-color: #222222; text-align: center"
    >
      <v-lazy>
        <router-link
          :to="{ path: '/launches', query: { tab: 'upcoming-launches' } }"
          style="text-decoration: none"
          ><v-container transition="slide-y-transition" style="padding: 0px"
            ><h4 style="color: white">
              Incoming Mission: {{ upcoming }}
            </h4></v-container
          >
        </router-link>
      </v-lazy>
    </div>
    <v-parallax height="800" src="../assets/img/launch_profile.jpg">
      <v-lazy transition="scroll-y-reverse-transition">
        <div style="margin-left: 6%; margin-right: 10%">
          <img
            src="../assets/img/launch_chars.png"
            height="50px"
            style="margin-left: -15px"
          />
          <h1>CNSA Launch Missions</h1>
          <router-link
            to="/launches"
            style="text-decoration: none; color: white"
            ><v-btn outlined dark min-width="200" min-height="50"
              ><h3>LEARN MORE</h3></v-btn
            ></router-link
          >
        </div>
      </v-lazy>
    </v-parallax>
    <v-parallax height="800" src="../assets/img/tiangong_profile.jpeg"
      ><v-lazy transition="scroll-y-reverse-transition">
        <div style="margin-left: 6%; margin-right: 10%">
          <img
            src="../assets/img/tiangong_chars.png"
            height="50px"
            style="margin-left: -15px"
          />
          <h1>Tiangong Space Station (CSS) Gallery</h1>
          <router-link
            to="/space-station"
            style="text-decoration: none; color: white"
            ><v-btn outlined dark min-width="200" min-height="50"
              ><h3>LEARN MORE</h3></v-btn
            ></router-link
          >
        </div>
      </v-lazy></v-parallax
    >
    <v-parallax height="800" src="../assets/img/zhurong_profile.jpeg">
      <v-lazy transition="scroll-y-reverse-transition">
        <div style="margin-left: 6%; margin-right: 10%">
          <img
            src="../assets/img/zhurong_chars.png"
            height="50px"
            style="margin-left: -15px"
          />
          <h1>Tianwen Zhurong Mars Mission Gallery</h1>
          <router-link
            to="/mars-rover"
            style="text-decoration: none; color: white"
            ><v-btn outlined dark min-width="200" min-height="50"
              ><h3>LEARN MORE</h3></v-btn
            ></router-link
          >
        </div>
      </v-lazy></v-parallax
    >

    <v-parallax height="800" src="../assets/img/yutu_profile.jpeg">
      <v-lazy transition="scroll-y-reverse-transition">
        <div style="margin-left: 6%; margin-right: 10%">
          <img
            src="../assets/img/yutu_chars.png"
            height="50px"
            style="margin-left: -15px"
          />
          <h1>Chang'e Yutu Moon Mission Gallery</h1>
          <router-link
            to="/moon-rover"
            style="text-decoration: none; color: white"
            ><v-btn outlined dark min-width="200" min-height="50"
              ><h3>LEARN MORE</h3></v-btn
            ></router-link
          >
        </div>
      </v-lazy>
    </v-parallax>
    <!-- </v-sheet> -->

    <!-- </v-tab-item>
      </v-tabs-items> -->
    <!-- </v-main> -->
    <!-- </v-app> -->
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      message: "Welcome to Your Vue.js App",
      upcoming: "",
      dataListUpcoming: [],
      dataListValuesUpcoming: [],
      jsonUpcomingURL:
        "https://watcher-3eeb5-default-rtdb.firebaseio.com/upcoming",
    };
  },
  created() {
    this.$gtag.event("home", { method: "Google" });
    // this.getDataUpcoming();
    this.getUpcoming();
  },
  methods: {
    getDataUpcoming: async function () {
      let time = new Date().getTime();
      let timeUpcoming = localStorage.getItem("timeUpcoming");
      if (timeUpcoming != null && time - timeUpcoming < 1000 * 60 * 1) {
        this.dataListUpcoming = JSON.parse(
          localStorage.getItem("dataListUpcoming")
        );
        this.dataListValuesUpcoming = JSON.parse(
          localStorage.getItem("dataListValuesUpcoming")
        );
        this.loadingUpcoming = false;
        return;
      }

      const response = await fetch(this.jsonUpcomingURL + ".json");
      if (response.ok) {
        this.dataListUpcoming = await response.json();
      } else {
        return;
      }
      let result = [];
      for (let key in this.dataListUpcoming) {
        let element = {};
        for (let key_element in this.dataListUpcoming[key]) {
          element[key_element] = this.dataListUpcoming[key][key_element];
        }
        element["remote_id"] = key;
        result.push(element);
      }
      this.dataListValuesUpcoming = result;
      this.loadingUpcoming = false;

      localStorage.setItem(
        "dataListValuesUpcoming",
        JSON.stringify(this.dataListValuesUpcoming)
      );
      localStorage.setItem(
        "dataListUpcoming",
        JSON.stringify(this.dataListUpcoming)
      );
      localStorage.setItem("timeUpcoming", new Date().getTime());
    },
    async getUpcoming() {
      await this.getDataUpcoming();
      if (this.dataListValuesUpcoming.length > 0) {
        this.dataListValuesUpcoming.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });
        for (let i = 0; i < this.dataListValuesUpcoming.length; i++) {
          if (this.dataListValuesUpcoming[i].date >= this.getUTCDate()) {
            this.upcoming = this.dataListValuesUpcoming[i].notification;
            return;
          }
        }

        // this.upcoming = this.dataListValuesUpcoming[0].notification;
      }
    },
    getUTCDate() {
      //get date in yyyy-mm-dd format
      let day = new Date();
      return (
        day.getUTCFullYear() +
        "-" +
        ("00" + (day.getUTCMonth() + 1)).slice(-2) +
        "-" +
        ("00" + day.getUTCDate()).slice(-2)
      );
    },
  },
};
</script>

<style scoped>
/* .body{
    font-family:sans-serif;
} */
</style>
